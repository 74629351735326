import { safeParseFloat } from '../utils'
import {
  TXeroBalanceSheet,
  XeroBalanceSheetByOrganizationResultXeroBalanceSheetNode,
} from './types'

export function xeroBalanceSheetNodeToXeroBalanceSheetObj(
  XeroBalanceSheetNode:
    | XeroBalanceSheetByOrganizationResultXeroBalanceSheetNode
    | undefined
    | null,
): TXeroBalanceSheet | undefined {
  if (!XeroBalanceSheetNode) return
  return {
    reference: XeroBalanceSheetNode.reference || undefined,
    amount: safeParseFloat(XeroBalanceSheetNode.amount) || 0,
    reportDate: XeroBalanceSheetNode.reportDate || undefined,
  }
}
