import { useAllMembersQuery, useGetStatsQuery } from '..'
import {
  useAllCountriesQuery,
  useAllCountriesWithCurrenciesQuery,
  useAllCurrenciesQuery,
  useClosestExchangeRateQuery,
  useGetCurrencyQuery,
  useGetGivingSeasonStatsQuery,
  useGetFundraiserStatsQuery,
} from '../graphql'
import {
  flattenGQLArrayResult,
  makeQueryWithReducer,
  parseReducerResult,
} from '../utils'
import { ExchangeRateParser } from './validations'

export * from './types'
export * from './validations'

export const useAllCurrenciesQueryWithReducer = makeQueryWithReducer(
  useAllCurrenciesQuery,
  (result) => flattenGQLArrayResult(result.data?.Currencies),
)

export const useAllCountriesQueryWithReducer = makeQueryWithReducer(
  useAllCountriesQuery,
  (result) => flattenGQLArrayResult(result.data?.Countries),
)

export const useAllCountriesWithCurrenciesQueryWithReducer =
  makeQueryWithReducer(useAllCountriesWithCurrenciesQuery, (result) =>
    flattenGQLArrayResult(result.data?.Countries)?.map((country) => ({
      ...country,
      currencies: country.currencies.map((currency) => currency.currencyCode),
    })),
  )

export const useGetCurrencyQueryWithReducer = makeQueryWithReducer(
  useGetCurrencyQuery,
  (result) => result.data?.Currency,
)

export const useClosestExchangeRateQueryWithReducer = makeQueryWithReducer(
  useClosestExchangeRateQuery,
  parseReducerResult((result) => result.data?.ExchangeRate, ExchangeRateParser),
)

export const useGetGivingSeasonStatsQueryWithReducer = makeQueryWithReducer(
  useGetGivingSeasonStatsQuery,
  (result) => flattenGQLArrayResult(result.data?.GivingSeasonStats),
)

export const useGetFundraiserStatsQueryWithReducer = makeQueryWithReducer(
  useGetFundraiserStatsQuery,
  (result) => flattenGQLArrayResult(result.data?.FundraiserStats),
)

export const useGetStatsQueryWithReducer = makeQueryWithReducer(
  useGetStatsQuery,
  (result) => {
    if (!result.data) {
      return undefined
    }
    const stats = result.data.allGlobalStatsList?.[0]
    if (!stats) {
      return undefined
    }
    return {
      GivingWhatWeCanPledge: {
        totalCount: stats.gwwcPledgesTotal,
      },
      TryGivingPledgeCount: {
        totalCount: stats.activeTryGivingPledgesTotal,
      },
      CompletedTryGivingPledge: {
        totalCount: stats.tryGivingCompletedTotal,
      },
      ProjectedDonations: {
        projectedDonationsTotal: stats.projectedDonationsTotal,
      },
      TotalGWWCDonations: stats.pledgesDonationsTotal,
      GWWCMemberCountries: stats.pledgersCountriesTotal,
    }
  },
)

export const useAllMembersWithReducer = makeQueryWithReducer(
  useAllMembersQuery,
  (result) => {
    return {
      GivingWhatWeCanPledges: result.data?.Pledges?.filter(
        ({ active, pledgeType }) =>
          active && pledgeType === 'GIVING_WHAT_WE_CAN',
      ),
      TryGivingPledges: result.data?.Pledges?.filter(
        ({ active, pledgeType }) => active && pledgeType === 'TRY_GIVING',
      ),
      InactiveTryGivingPledgesCount: result.data?.Pledges?.filter(
        ({ active, pledgeType }) => !active && pledgeType === 'TRY_GIVING',
      ).length,
    }
  },
)
