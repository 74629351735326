import { isExistent } from '@aletheia/common/utils/guards'

import { safeParseFloat } from '../utils'
import {
  EPaymentRecipient,
  ESupportedCurrencyCode,
  GetPaymentsQueryResultPaymentNode,
  TAllocation,
  TAllocationItem,
  TPayment,
  TRecurringPayment,
  TPaymentLikeGatewayData,
  TPaymentLikeMetadata,
  TPaymentResult,
  TRecurringPaymentResult,
  TPaymentLikeStatusData,
} from './types'

/**
 * Casts a Payment node from a database query or mutation to a TPayment object
 *
 * @param PaymentNode Leaf node of `GetPaymentsQueryResult` or mutation result
 *     of `CreatePaymentMutation`
 */
export function paymentNodeToPaymentObj(PaymentNode: TPaymentResult): TPayment {
  return {
    __typename: 'Payment',
    id: PaymentNode.id,
    personId: PaymentNode.personId,
    // Numeric fields
    chargeAmount: safeParseFloat(PaymentNode.chargeAmount),
    amount: safeParseFloat(PaymentNode.amount),
    amountNormalized: safeParseFloat(PaymentNode.amountNormalized),
    net: safeParseFloat(PaymentNode.net),
    fee: safeParseFloat(PaymentNode.fee),
    // Currencies
    chargeCurrencyCode:
      PaymentNode.chargeCurrencyCode as ESupportedCurrencyCode,
    currencyCode:
      (PaymentNode.currencyCode as ESupportedCurrencyCode) || undefined,
    // Recipient
    recipient: PaymentNode.recipient as EPaymentRecipient,
    // Allocation
    allocation: (PaymentNode.allocation as []).filter(isValidAllocationItem),
    // Gateway fields
    gateway: PaymentNode.gateway,
    gatewayData: PaymentNode.gatewayData as TPaymentLikeGatewayData,
    gatewayTransactionId: PaymentNode.gatewayTransactionId || undefined,
    // Payment method
    paymentMethod: PaymentNode.paymentMethod || undefined,
    // Metadata
    recurrence: PaymentNode.recurrence,
    metadata: PaymentNode.metadata as TPaymentLikeMetadata,
    // Status
    status: PaymentNode.status,
    statusData: PaymentNode.statusData as TPaymentLikeStatusData,
    // Reference
    reference: PaymentNode.reference || undefined,
    // Dates
    createdAt: PaymentNode.createdAt,
    updatedAt: PaymentNode.updatedAt,
    // Links
    _links: {
      giftAidClaim:
        (
          PaymentNode as GetPaymentsQueryResultPaymentNode
        ).giftAidClaimsByPaymentIdAndPersonId?.edges.filter(isExistent)[0]
          ?.node || undefined,
    },
  }
}

/**
 * Casts a Payment node from `GetRecurringPaymentsQuery` to a TRecurringPayment
 * object
 *
 * @param RecurringPaymentNode Leaf node of `GetRecurringPaymentsQueryResult`
 */
export function recurringPaymentNodeToRecurringPaymentObj(
  RecurringPaymentNode: TRecurringPaymentResult,
): TRecurringPayment {
  return {
    __typename: 'RecurringPayment',
    // IDs
    id: RecurringPaymentNode.id,
    personId: RecurringPaymentNode.personId || undefined,
    // Numeric fields
    amount: safeParseFloat(RecurringPaymentNode.amount),
    // Recipient
    recipient: RecurringPaymentNode.recipient as EPaymentRecipient,
    // Currencies
    currencyCode: RecurringPaymentNode.currencyCode as ESupportedCurrencyCode,
    // Allocation
    allocation: (RecurringPaymentNode.allocation as []).filter(
      isValidAllocationItem,
    ),
    // Gateway
    gateway: RecurringPaymentNode.gateway,
    gatewayData: RecurringPaymentNode.gatewayData as TPaymentLikeGatewayData,
    // Payment method
    paymentMethod: RecurringPaymentNode.paymentMethod || undefined,
    // Period
    period: RecurringPaymentNode.period,
    periodIndex: RecurringPaymentNode.periodIndex,
    periodInterval: RecurringPaymentNode.periodInterval,
    status: RecurringPaymentNode.status,
    statusData: RecurringPaymentNode.statusData as TPaymentLikeStatusData,
    // dates
    createdAt: RecurringPaymentNode.createdAt,
    updatedAt: RecurringPaymentNode.updatedAt,
  }
}

/** Guard to test if a payment-like object is a Payment */
export function isPayment(arg: unknown): arg is TPayment {
  if (!arg) return false
  return (arg as TPayment).__typename === 'Payment'
}

/** Guard to test if a payment-like object is a Recurring Payment */
export function isRecurringPayment(arg: unknown): arg is TRecurringPayment {
  if (!arg) return false
  return (arg as TRecurringPayment).__typename === 'RecurringPayment'
}

/** Guard to test if an object is a valid `TAllocationItem` */
export function isValidAllocationItem(
  allocation: unknown,
): allocation is TAllocationItem {
  if (!allocation) return false
  return (
    typeof (allocation as TAllocationItem).organization === 'string' &&
    typeof (allocation as TAllocationItem).percentage === 'number' &&
    !Number.isNaN((allocation as TAllocationItem).percentage) &&
    (allocation as TAllocationItem).percentage >= 0 &&
    (allocation as TAllocationItem).percentage <= 100
  )
}

/** Guard to test if an object is a valid allocation */
export function isValidAllocation(Allocation?: TAllocation): boolean {
  if (!Allocation) return false
  if (Allocation.length === 0) return true
  if (!Allocation.every(isValidAllocationItem)) return false
  const sum: number = Allocation.reduce(
    (sum, AllocationItem) => sum + AllocationItem.percentage,
    0,
  )
  return sum === 100
}
