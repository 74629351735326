import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'
import React from 'react'

type TErrorDisplay = {
  test: string | RegExp
  ErrorDisplay: React.ComponentType<{ error: Error }>
  ErrorAction: React.ComponentType<{ onClose?: () => void }>
}

const CUSTOM_ERRORS: TErrorDisplay[] = [
  {
    test: 'Could not retrieve access token with correct permissions',
    ErrorDisplay() {
      return (
        <>
          <DialogContentText>
            Sorry, you don{`'`}t have permission to access this application.
          </DialogContentText>
        </>
      )
    },
    ErrorAction() {
      return (
        <Button href="/api/auth/logout" variant="contained" color="primary">
          Log Out
        </Button>
      )
    },
  },
  {
    test: 'Email does not match JWT claims',
    ErrorDisplay() {
      return (
        <>
          <DialogContentText>
            The email you{`'`}ve logged in with doesn{`'`}t match our records.
            This can happen if you{`'`}ve changed your email but haven{`'`}t
            logged in again.{' '}
          </DialogContentText>
          <DialogContentText>Please log in again.</DialogContentText>
        </>
      )
    },
    ErrorAction() {
      return (
        <Button href="/api/auth/login" variant="contained" color="primary">
          Log in
        </Button>
      )
    },
  },
]

const DEFAULT_ERROR: TErrorDisplay = {
  test: /.*/,
  ErrorDisplay({ error }) {
    return (
      <>
        <DialogContentText>{error.message}</DialogContentText>
      </>
    )
  },
  ErrorAction({ onClose }) {
    return <Button onClick={onClose}>Close</Button>
  },
}

type AuthErrorDisplayProps = {
  error?: Error
  onClose: () => void
}

const AuthErrorDisplay: React.FC<AuthErrorDisplayProps> = ({
  error,
  onClose,
}) => {
  if (!error) return null

  const errorDisplay =
    CUSTOM_ERRORS.find(({ test }) => {
      if (typeof test === 'string') return error.message.includes(test)
      return test.test(error.message)
    }) || DEFAULT_ERROR
  const { ErrorDisplay, ErrorAction } = errorDisplay
  return (
    <>
      <DialogContent>
        <ErrorDisplay error={error} />
      </DialogContent>
      {ErrorAction && (
        <DialogActions>
          <ErrorAction onClose={onClose} />
        </DialogActions>
      )}
    </>
  )
}

export default AuthErrorDisplay
