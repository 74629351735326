import { z } from 'zod'

import { numeric } from '@aletheia/common/utils/zod'

export const ExchangeRateParser = z.object({
  currencyCode: z.string(),
  date: z.string(),
  rate: numeric,
})

export type TExchangeRate = z.infer<typeof ExchangeRateParser>
