// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from "@sentry/nextjs";
var SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
Sentry.init({
    dsn: SENTRY_DSN || "https://c61fa288fc4c4c7cbec4c8c1fd443f07@o241118.ingest.sentry.io/6417525",
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0
});
