import { isExistent } from '../../common/utils/guards'
import {
  useGetPaymentsQuery,
  useGetRecurringPaymentsQuery,
  useGetGocardlessMandatesQuery,
  useGetGocardlessRedirectFlowQuery,
  useGetLotteryByIdQuery,
  useGetLotteryEntriesCompleteQuery,
  useGetLotteriesQuery,
  useGetLotterySummaryQuery,
  useGetLotterySummariesQuery,
  useGetRecurringPaymentQuery,
  useGetAllRecipientProgramsQuery,
} from '../graphql'
import {
  flattenGQLArrayResult,
  makeQueryWithReducer,
  isObjectWithId,
  parseReducerResult,
} from '../utils'
import { useWithPerson } from '../utils/useWithPerson'
import {
  paymentNodeToPaymentObj,
  recurringPaymentNodeToRecurringPaymentObj,
} from './utils'
import {
  LotteriesParser,
  LotteryEntriesCompleteParser,
  LotterySummariesParser,
  LotterySummaryParser,
} from './validations'

export * from './types'
export * from './validations'
export * from './utils'

export const useGetPaymentsQueryWithReducer = makeQueryWithReducer(
  useGetPaymentsQuery,
  (result) =>
    flattenGQLArrayResult(result.data?.Payments)?.map(paymentNodeToPaymentObj),
  useWithPerson,
)

export const useGetRecurringPaymentsQueryWithReducer = makeQueryWithReducer(
  useGetRecurringPaymentsQuery,
  (result) =>
    flattenGQLArrayResult(result.data?.RecurringPayments)?.map(
      recurringPaymentNodeToRecurringPaymentObj,
    ),
  useWithPerson,
)

export const useGetRecurringPaymentQueryWithReducer = makeQueryWithReducer(
  useGetRecurringPaymentQuery,
  (result) => result.data?.recurringPayment,
)

export const useGetGocardlessMandatesQueryWithReducer = makeQueryWithReducer(
  useGetGocardlessMandatesQuery,
  (result) => flattenGQLArrayResult(result.data?.gocardlessMandates),
)

export const useGetGocardlessRedirectFlowQueryWithReducer =
  makeQueryWithReducer(
    useGetGocardlessRedirectFlowQuery,
    (result) => result.data?.GocardlessRedirectFlow,
  )

export const useGetLotteryQueryWithReducer = makeQueryWithReducer(
  useGetLotteryByIdQuery,
  (result) => result.data?.Lottery,
)

export const useGetLotterySummaryQueryWithReducer = makeQueryWithReducer(
  useGetLotterySummaryQuery,
  parseReducerResult(
    (result) => result.data?.LotterySummary,
    LotterySummaryParser,
  ),
)

export const useGetLotteriesQueryWithReducer = makeQueryWithReducer(
  useGetLotteriesQuery,
  parseReducerResult(
    (result) =>
      flattenGQLArrayResult(result.data?.Lotteries)
        ?.filter(isExistent)
        .filter(isObjectWithId),
    LotteriesParser,
  ),
)

export const useGetLotterySummariesQueryWithReducer = makeQueryWithReducer(
  useGetLotterySummariesQuery,
  parseReducerResult(
    (result) =>
      flattenGQLArrayResult(result.data?.Lotteries)
        ?.filter(isExistent)
        .filter(isObjectWithId),
    LotterySummariesParser,
  ),
)

export const useGetLotteryEntriesCompleteQueryWithReducer =
  makeQueryWithReducer(
    useGetLotteryEntriesCompleteQuery,
    parseReducerResult((result) => {
      return flattenGQLArrayResult(result.data?.LotteryEntriesComplete)?.filter(
        isExistent,
      )
    }, LotteryEntriesCompleteParser),
  )

export const useGetAllRecipientProgramsQueryWithReducer = makeQueryWithReducer(
  useGetAllRecipientProgramsQuery,
  (result) => {
    const recipientPrograms = new Map<string, string[]>()
    const data = result.data?.RecipientPrograms || []
    for (const { recipientSlug, organizationSlug } of data) {
      if (recipientPrograms.has(recipientSlug)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        recipientPrograms.get(recipientSlug)!.push(organizationSlug)
      } else {
        recipientPrograms.set(recipientSlug, [organizationSlug])
      }
    }
    return recipientPrograms
  },
)
