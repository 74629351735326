import { useGetIncomesByPersonIdQuery } from '..'
import { flattenGQLArrayResult, makeQueryWithReducer } from '../utils'
import { useWithPerson } from '../utils/useWithPerson'

export * from './types'

export const useGetIncomesByPersonIdQueryWithReducer = makeQueryWithReducer(
  useGetIncomesByPersonIdQuery,
  (result) => flattenGQLArrayResult(result.data?.Incomes),
  useWithPerson,
)
