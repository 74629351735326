import { OrganizationType } from '../graphql'
import { TOrganization } from './types'

export function getOrganizationsByType(
  Organizations: TOrganization[] | undefined,
  type: OrganizationType,
): TOrganization[] {
  if (!Organizations) return []
  return Organizations.filter((Org) => Org.type === type)
}

export function getOrganizationsByActive(
  Organizations: TOrganization[] | undefined,
  active = true,
): TOrganization[] {
  if (!Organizations) return []
  return Organizations.filter((Org) => (active ? Org.active : !Org.active))
}
