import { useEffect, useRef, useState } from 'react'

import { ParsedError } from './errors'

export const LOCALSTORAGE_BASE_NAMESPACE_ID =
  process.env.NODE_ENV !== 'test'
    ? process.env.NEXT_PUBLIC_APP_IDENTIFIER
    : 'test_app'

export const LOCALSTORAGE_BASE_NAMESPACE = `@aletheia.${LOCALSTORAGE_BASE_NAMESPACE_ID}`

export function useLocalStorage<T>(
  key: string,
  initialValue: T,
  {
    onLoaded,
    convertBeforeLoad,
  }: {
    onLoaded?: (value: T) => void
    convertBeforeLoad?: (loadedValue: any) => T
  } = {},
): [
  T,
  (value: T | ((prevValue: T) => T)) => void,
  { loaded: boolean; initialized: boolean },
] {
  const keyWithNamespace = `${LOCALSTORAGE_BASE_NAMESPACE}.${key}`
  /**
   * State to store our value Pass initial state function to useState so the value is always set
   */
  const [storedValue, setStoredValue] = useState<T>(initialValue)
  const [loaded, setLoaded] = useState(false)
  const [initialized, setInitialized] = useState(false)
  /**
   * UseEffect to pull in LocalStorage values after hydration see
   * https://joshwcomeau.com/react/the-perils-of-rehydration/
   */
  useEffect(() => {
    if (typeof window === 'undefined') return
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(keyWithNamespace)
      // Parse stored json or if none return initialValue
      const value = item && JSON.parse(item)
      if (value) {
        const convertedValue = convertBeforeLoad
          ? convertBeforeLoad(value)
          : value
        setStoredValue(convertedValue)
        setLoaded(true)
        if (onLoaded) onLoaded(value)
      }
      setInitialized(true)
    } catch (error) {
      // If error also return initialValue
      console.error(error)
    }
    // we very definitely only want this to run once
    // so don't add onLoaded/storedValue/anything else to the
    // dependencies array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyWithNamespace])

  useEffect(() => {
    // Save to local storage when it's initialized
    if (initialized) {
      window.localStorage.setItem(keyWithNamespace, JSON.stringify(storedValue))
    }
  }, [storedValue, initialized, keyWithNamespace])

  return [storedValue, setStoredValue, { loaded, initialized }]
}

/**
 * Debounce useEffect so that it only runs after deps haven't changed for a
 * certain period of time
 *
 * Idea from https://stackoverflow.com/a/61127960/7114675
 *
 * @param effect
 * @param delay
 * @param deps
 */
export function useEffectDebounced(
  effect: () => void,
  delay: number,
  deps: unknown[],
): void {
  useEffect(() => {
    const handler = setTimeout(() => {
      effect()
    }, delay)

    return () => {
      clearTimeout(handler)
    }
    // We only want to re-run if the parent deps change
    // effect() is re-created every render, so will cause an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, delay])
}

/** Store the previous value of a variable */
export function usePrevious<T>(value: T): T {
  const ref = useRef<T>(value)
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

/**
 * Listen for keydown events
 *
 * From https://usehooks.com/useKeyPress/
 */
export function useKeyDown(targetKey: string): boolean {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false)

  // If pressed key is our target key then set to true
  function downHandler({ key }: { key: string }) {
    if (key === targetKey) {
      setKeyPressed(true)
    }
  }

  // If released key is our target key then set to false
  const upHandler = ({ key }: { key: string }) => {
    if (key === targetKey) {
      setKeyPressed(false)
    }
  }

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // Empty array ensures that effect is only run on mount and unmount

  return keyPressed
}

/**
 * Get the visitor's country by IP address, using the `country.is` API: https://country.is/
 *
 * Copied almost wholesale from https://github.com/TechToThePeople/react-ipgeolocation/blob/main/index.js
 * unfortunately they haven't published the package correctly and using it causes
 * a `Cannot use import statement outside a module` error, so I'm effectively
 * vendoring it in
 */

export const useGeoLocation = (): {
  country: string | undefined
  error: Error | undefined
  loading: boolean
} => {
  const [country, setCountry] = useState<string>()
  const [error, setError] = useState<Error>()
  const [loading, setLoading] = useState(false)
  const api = 'https://api.country.is'

  useEffect(() => {
    // Use an AbortController to handle cancellation of the request if the component unmounts
    // Cf. https://dev.to/pallymore/clean-up-async-requests-in-useeffect-hooks-90h
    const abortCtrl = new AbortController()

    const getCountry = async () => {
      try {
        setLoading(true)
        setError(undefined)
        const { country } = await fetch(api, {
          signal: abortCtrl.signal,
        }).then((res) => res.json())
        setCountry(country)
      } catch (err) {
        if (!abortCtrl.signal.aborted) {
          setError(new ParsedError(err))
        }
      } finally {
        if (!abortCtrl.signal.aborted) setLoading(false)
      }
    }
    getCountry()
    return () => {
      abortCtrl.abort()
    }
  }, [])

  return { country, error, loading }
}
