import { Referral } from '@aletheia/common/components/Referrals'

import { Campaign } from '../../givingwhatwecan/components/Campaign/types'
import { Fundraiser } from '../../givingwhatwecan/components/Fundraiser/types'
import {
  GetPaymentsQueryResult,
  CreatePaymentMutationResult,
  GetRecurringPaymentsQueryResult,
  CreateRecurringPaymentMutationResult,
  GetGocardlessMandatesQueryResult,
  PaymentGateway,
  PaymentMethod,
  PaymentRecurrence,
  PaymentStatus,
  RecurrencePeriod,
  RecurringPaymentStatus,
  GetPaymentQueryResult,
} from '../graphql'

/** Payment node as returned by GetPaymentsQuery */
export type GetPaymentsQueryResultPaymentNode = NonNullable<
  NonNullable<
    NonNullable<GetPaymentsQueryResult['data']>['Payments']
  >['edges'][0]['node']
>

/** Payment node as returned by GetPaymentsQuery */
export type GetPaymentQueryResultPayment = NonNullable<
  NonNullable<NonNullable<GetPaymentQueryResult['data']>['Payment']>
>

/** Payment node as returned by CreatePaymentMutation */
export type CreatePaymentMutationResultPayment = NonNullable<
  NonNullable<
    NonNullable<CreatePaymentMutationResult['data']>['mutationResult']
  >['payment']
>

/**
 * A Payment result returned from the database, either by a query or a mutation
 *
 * Used for casting between the database result and a properly-formatted
 * TPayment
 */
export type TPaymentResult =
  | GetPaymentsQueryResultPaymentNode
  | GetPaymentQueryResultPayment
  | CreatePaymentMutationResultPayment

/**
 * Gift Aid Claim node as returned in the `giftAidClaimsByPaymentIdAndPersonId`
 * field of GetPaymentsQuery
 */
export type GetPaymentsQueryResultGiftAidClaimNode = NonNullable<
  GetPaymentsQueryResultPaymentNode['giftAidClaimsByPaymentIdAndPersonId']['edges'][0]['node']
>

/** Recurring Payment node as returned by GetRecurringPaymentsQuery */
export type GetRecurringPaymentsQueryResultRecurringPaymentNode = NonNullable<
  NonNullable<
    NonNullable<GetRecurringPaymentsQueryResult['data']>['RecurringPayments']
  >['edges'][0]['node']
>

/** Recurring Payment node as returned by CreateRecurringPaymentMutation */
export type CreateRecurringPaymentMutationResultRecurringPayment = NonNullable<
  NonNullable<
    NonNullable<CreateRecurringPaymentMutationResult['data']>['mutationResult']
  >['recurringPayment']
>

/**
 * A Recurring Payment result returned from the database, either by a query or a
 * mutation
 *
 * Used for casting between the database result and a properly-formatted
 * TRecurringPayment
 */
export type TRecurringPaymentResult =
  | GetRecurringPaymentsQueryResultRecurringPaymentNode
  | CreateRecurringPaymentMutationResultRecurringPayment

/** Payment object after parsing/formatting */
export type TPayment = {
  __typename: 'Payment'
  id: string
  personId: string
  chargeCurrencyCode: ESupportedCurrencyCode
  chargeAmount: number
  currencyCode?: ESupportedCurrencyCode
  amount?: number
  net?: number
  fee?: number
  amountNormalized?: number
  recipient: EPaymentRecipient
  allocation: TAllocation
  gateway: PaymentGateway
  gatewayData?: TPaymentLikeGatewayData
  gatewayTransactionId?: string
  paymentMethod?: PaymentMethod
  recurrence: PaymentRecurrence
  metadata?: TPaymentLikeMetadata
  status: PaymentStatus
  statusData?: TPaymentLikeStatusData
  reference?: string
  billingAddressId?: string
  createdAt: string
  updatedAt: string
  _links: TPaymentLinks
}

/** Recurring Payment object after parsing/formatting */
export type TRecurringPayment = {
  __typename: 'RecurringPayment'
  id: string
  personId?: string
  period: RecurrencePeriod
  periodIndex: number
  periodInterval: number
  allocation: TAllocation
  amount: number
  currencyCode: string
  recipient: EPaymentRecipient
  gateway: PaymentGateway
  gatewayData?: TPaymentLikeGatewayData
  paymentMethod?: PaymentMethod
  metadata?: TPaymentLikeMetadata
  status: RecurringPaymentStatus
  statusData?: TPaymentLikeStatusData
  billingAddressId?: string
  createdAt: string
  updatedAt: string
}

/** Either a TPayment or TRecurringPayment */
export type TPaymentLike = TPayment | TRecurringPayment

/** Allowed shape of the Payment.metadata object */
export type TPaymentLikeMetadata = {
  gift_aid?: TPaymentLikeMetadataGiftAid
  share_with_partners?: TPaymentLikeMetadataPartnerSharing
  origin?: string
  country?: string
  target_currency_code?: string
  referral?: Referral
  lottery?: TPaymentLikeMetadataLottery
  app_id?: string
  campaign?: TCampaignMetadata
  fundraiser?: TFundraiserMetadata
}

/** Shape of Payment.metadata.gift_aid */
export type TPaymentLikeMetadataGiftAid = {
  claimed: boolean
  address_id?: string
}

/** Shape of Payment.metadata.share_with_partners */
export type TPaymentLikeMetadataPartnerSharing = {
  share: boolean
}

/** Shape of general metadata data sharing */
export type TPaymentLikeMetadataDataSharing = {
  share_full_name?: boolean
  share_email?: boolean
}

/** Shape of Payment.metadata.campaign */
export type TCampaignMetadata = Campaign & {
  data_sharing?: TPaymentLikeMetadataDataSharing
}

/** Shape of Payment.metadata.fundraiser */
export type TFundraiserMetadata = Fundraiser & {
  data_sharing?: TPaymentLikeMetadataDataSharing
}

export type TPaymentLikeMetadataLottery = {
  anonymous: boolean
  lottery_id: string
}

/** Allowed shape of the Payment.gatewayData object */
export type TPaymentLikeGatewayData =
  | TPaymentLikeGatewayDataStripe
  | TPaymentLikeGatewayDataGoCardless

/** Shape of Payment.gatewayData when Payment.gateway = 'STRIPE' */
export type TPaymentLikeGatewayDataStripe = {
  payment_intent?: string
  payment_intent_client_secret?: string
  payment_method?: string
  subscription?: string
  card_last_4_digits?: string
}

/** Shape of Payment.gatewayData when Payment.gateway = 'STRIPE' */
export type TPaymentLikeGatewayDataGoCardless = {
  mandate?: string
  subscription?: string
}

/** Allowed shape of the Payment.statusData object */
export type TPaymentLikeStatusData = {
  error: string
  [x: string]: unknown
}

/** Objects that could be linked to a Payment node via a foreign key relationship */
export type TPaymentLinks = {
  giftAidClaim?: GetPaymentsQueryResultGiftAidClaimNode
}

export type TAllocationItem = {
  organization: string
  percentage: number
  isExtra?: boolean
}
export type TAllocation = TAllocationItem[]

/** Enum describing a valid payment recipient */
export enum EPaymentRecipient {
  'CEA_US' = 'cea-us',
  'CEA_UK' = 'cea-uk',
  'RC_CA' = 'rc-ca',
}

export const enabledPaymentRecipients =
  process.env.NEXT_PUBLIC_FEATURE_FLAG_RC_ENABLED === 'true'
    ? [
        EPaymentRecipient.CEA_UK,
        EPaymentRecipient.CEA_US,
        EPaymentRecipient.RC_CA,
      ]
    : [EPaymentRecipient.CEA_UK, EPaymentRecipient.CEA_US]

/* Enum describing a supported currency code */
export enum ESupportedCurrencyCode {
  'USD' = 'USD',
  'GBP' = 'GBP',
  'CAD' = 'CAD',
  'EUR' = 'EUR',
  'LTC' = 'LTC',
  'ETH' = 'ETH',
  'BTC' = 'BTC',
}

/** GoCardless Mandate */
export type TGocardlessMandate = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<
        GetGocardlessMandatesQueryResult['data']
      >['gocardlessMandates']
    >['edges'][0]
  >['node']
>
