import {
  useGetOrganizationsQuery,
  useGetExternalOrganizationsQuery,
  useGetExternalOrganizationTypeaheadQuery,
} from '../graphql'
import {
  flattenGQLArrayResult,
  flattenGQLArrayResultKeyed,
  makeQueryWithReducer,
} from '../utils'

export * from './types'
export * from './utils'

export const useGetOrganizationsQueryWithReducer = makeQueryWithReducer(
  useGetOrganizationsQuery,
  (result) => flattenGQLArrayResult(result.data?.Organizations),
)

export const useGetOrganizationsQueryWithReducerKeyed = makeQueryWithReducer(
  useGetOrganizationsQuery,
  (result) => flattenGQLArrayResultKeyed(result.data?.Organizations, 'slug'),
)

export const useGetExternalOrganizationsQueryWithReducer = makeQueryWithReducer(
  useGetExternalOrganizationsQuery,
  (result) => flattenGQLArrayResult(result.data?.ExternalOrganizations),
)

export const useGetExternalOrganizationTypeaheadQueryWithReducer =
  makeQueryWithReducer(useGetExternalOrganizationTypeaheadQuery, (result) =>
    flattenGQLArrayResult(result.data?.ExternalOrganizations),
  )
