import {
  useGetCompletionPaymentsByPersonIdQuery,
  useGetLiabilityCompletionsQuery,
  useGetLiabilityTotalCompletionsQuery,
  useGetPledgesByPersonIdQuery,
  useGetPledgeRanksByPersonIdQuery,
  useGetActivePledgedMemberPublicsQuery,
  useGetPledgedMemberPublicsLatestRankQuery,
  useGetPledgeQuery,
  useGetRecurringReportedDonationsByPersonIdQuery,
  useGetReportedDonationsByPersonIdQuery,
  useGetLiabilitiesByPersonIdQuery,
} from '../graphql'
import { flattenGQLArrayResult, makeQueryWithReducer } from '../utils'
import { useWithPerson } from '../utils/useWithPerson'
import { TPledgeWithMetadata } from './types'

export * from './types'

export const useGetPledgesByPersonIdQueryWithReducer = makeQueryWithReducer(
  useGetPledgesByPersonIdQuery,
  (result) => flattenGQLArrayResult(result.data?.Pledges),
  useWithPerson,
)

/**
 * Allows to retrieve the latest Pledge Rank by Pledge Type (defaults to GIVING_WHAT_WE_CAN)
 */
export const useGetPledgedMemberPublicsLatestRankQueryWithReducer =
  makeQueryWithReducer(useGetPledgedMemberPublicsLatestRankQuery, (result) =>
    result.data?.PledgedMembers?.length
      ? result.data.PledgedMembers[0].rankByPledgeType
      : undefined,
  )

/**
 * Allows to retrieve the Pledge rank of the logged in person
 */
export const useGetPledgeRanksByPersonIdQueryWithReducer = makeQueryWithReducer(
  useGetPledgeRanksByPersonIdQuery,
  (result) => flattenGQLArrayResult(result.data?.PledgeRanks),
  useWithPerson,
)

/**
 * Allows to retrieve the public pledged members (will not contain the personId)
 */
export const useGetActivePublicPledgedMembersQueryWithReducer =
  makeQueryWithReducer(useGetActivePledgedMemberPublicsQuery, (result) =>
    flattenGQLArrayResult(result.data?.PledgedMembers),
  )

/**
 * Allows retrieving the pledge by providing the pledge id
 */
export const useGetPledgeQueryWithReducer = makeQueryWithReducer(
  useGetPledgeQuery,
  (result) => result.data?.Pledge as TPledgeWithMetadata,
)

/**
 * Retrieve all of the current user's reported donations
 */
export const useGetReportedDonationsByPersonIdQueryWithReducer =
  makeQueryWithReducer(
    useGetReportedDonationsByPersonIdQuery,
    (result) => flattenGQLArrayResult(result.data?.ReportedDonations),
    useWithPerson,
  )

/**
 * Retrieve all of the current user's reported recurring donations
 */
export const useGetRecurringReportedDonationsByPersonIdQueryWithReducer =
  makeQueryWithReducer(
    useGetRecurringReportedDonationsByPersonIdQuery,
    (result) => flattenGQLArrayResult(result.data?.RecurringReportedDonations),
    useWithPerson,
  )

export const useGetCompletionPaymentsByPersonIdQueryWithReducer =
  makeQueryWithReducer(
    useGetCompletionPaymentsByPersonIdQuery,
    (result) => flattenGQLArrayResult(result.data?.CompletionPayments),
    useWithPerson,
  )

export const useGetLiabilityTotalCompletionsQueryWithReducer =
  makeQueryWithReducer(useGetLiabilityTotalCompletionsQuery, (result) =>
    flattenGQLArrayResult(result.data?.LiabilityTotalCompletions),
  )

export const useGetLiabilityCompletionsQueryWithReducer = makeQueryWithReducer(
  useGetLiabilityCompletionsQuery,
  (result) => flattenGQLArrayResult(result.data?.LiabilityCompletions),
)

export const useGetLiabilitiesQueryWithReducer = makeQueryWithReducer(
  useGetLiabilitiesByPersonIdQuery,
  (result) => flattenGQLArrayResult(result.data?.Liabilities),
  useWithPerson,
)
