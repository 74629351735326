import { z } from 'zod'

import { numeric, int8range } from '@aletheia/common/utils/zod'

/**
 * The data returned by the API is an 'ExtendedLottery', which is based on a view.
 * Consequently, the generated types believe everything could be nullable. So,
 * just parse everything so that it's nice and strongly typed.
 */
export const LotteryParser = z.object({
  id: z.string(),
  blockSize: numeric,
  closeTimestamp: z.string(),
  lockTimestamp: z.string(),
  drawTimestamp: z.string(),
  drawEpoch: z.number(),
  status: z.enum(['open', 'finalizing', 'awaiting_draw', 'closed']),
  winningNumber: numeric.or(z.null()),
})

export type TLottery = z.infer<typeof LotteryParser>

export const LotteriesParser = z.array(LotteryParser)

export const LotteryEntryParser = z.object({
  lotteryId: z.string(),
  paymentReference: z.string(),
  amount: numeric,
  currencyCode: z.string(),
  amountNormalized: numeric,
  giftAid: numeric,
  total: numeric,
  displayName: z.string(),
  createdAt: z.string(),
})

export type TLotteryEntry = z.infer<typeof LotteryEntryParser>

export const LotteryEntriesParser = z.array(LotteryEntryParser)

export const LotteryTicketParser = z.object({
  lotteryId: z.string(),
  paymentReference: z.string().nullable(),
  blockId: z.number(),
  winRange: int8range,
  benefactor: z.boolean(),
})

export type TLotteryTicket = z.infer<typeof LotteryTicketParser>

export const LotteryTicketsParser = z.array(LotteryTicketParser)

export const LotteryWinnerParser = z.object({
  lotteryId: z.string(),
  paymentReference: z.string().nullable(),
  blockId: z.number(),
  benefactor: z.boolean(),
})

export type TLotteryWinner = z.infer<typeof LotteryWinnerParser>

export const LotteryWinnersParser = z.array(LotteryWinnerParser)

export const LotteryEntryCompleteParser = z.object({
  lotteryId: z.string(),
  paymentReference: z.string().nullable(),
  amountNormalized: numeric,
  displayName: z.string(),
  createdAt: z.string(),
  benefactor: z.boolean(),
  tickets: z
    .array(
      z.object({
        block_id: z.number(),
        win_range: z.tuple([z.number(), z.number()]),
        is_winner: z.boolean().nullable(),
      }),
    )
    .nullable(),
})

export type TLotteryEntryComplete = z.infer<typeof LotteryEntryCompleteParser>

export const LotteryEntriesCompleteParser = z.array(LotteryEntryCompleteParser)

export const LotterySummaryParser = z.object({
  id: z.string(),
  blockSize: numeric,
  closeTimestamp: z.string(),
  lockTimestamp: z.string(),
  drawTimestamp: z.string(),
  drawEpoch: numeric,
  status: z.enum(['open', 'closed', 'locked', 'drawn']),
  winningNumber: numeric.or(z.null()),
  winningNumberHex: z.string().nullable(),
  ticketsAssigned: z.boolean(),
  maxBlockId: z.number().nullable(),
  numEntries: z.number(),
  entriesTotalNormalized: numeric,
  benefactorLiability: numeric.nullable(),
  benefactorStartingTicketNumber: numeric.or(z.null()),
  benefactorWinsLastBlock: z.boolean().nullable(),
  winningTickets: z
    .array(
      z.object({
        block_id: z.number(),
        win_range: z.tuple([z.number(), z.number()]),
        payment_reference: z.string(),
      }),
    )
    .nullable(),
})
export type TLotterySummary = z.infer<typeof LotterySummaryParser>

export const LotterySummariesParser = z.array(LotterySummaryParser)
