import {
  useGetXeroBalanceSheetByOrganizationQuery,
  useGetPaymentStatsAggregateQuery,
} from '../graphql'
import { makeQueryWithReducer } from '../utils'
import { xeroBalanceSheetNodeToXeroBalanceSheetObj } from './utils'
import { PaymentStatsParser } from './validations'

export * from './types'
export * from './utils'

export const useGetXeroBalanceSheetByOrganizationQueryWithReducer =
  makeQueryWithReducer(useGetXeroBalanceSheetByOrganizationQuery, (result) =>
    xeroBalanceSheetNodeToXeroBalanceSheetObj(
      result.data?.XeroBalanceSheet?.edges[0]?.node,
    ),
  )

export const useGetPaymentStatsAggregateQueryWithReducer = makeQueryWithReducer(
  useGetPaymentStatsAggregateQuery,
  (result) => PaymentStatsParser.parse(result.data?.PaymentStats),
)
